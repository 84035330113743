@import 'variables.scss';
@import 'common.scss';

// Text Message
.user {
  @include messageBubble($light-grey, $dark-grey);
}
.bot {
  @include messageBubble($green-1, $white);
  margin-left: auto;
}

.imageMessage {
  img {
    border-radius: 10px;
    max-width: 300px;
  }
}

// Button Template
.buttonTemplate {
  @include flexStart;
  flex-direction: column;
  width: 318px;
  border: 0.25px solid $light-grey-2;
  .row {
    @include row();
  }
  button {
    @include button($blue-1);
  }
}

// Simplified Button Template
.simplifiedButtonTemplate {
  width: 318px;
  border: 0.25px solid $light-grey-2;
  .title {
    @include row();
    border-bottom: 0.25px solid $light-grey-2;
  }
  .buttons {
    @include flexCenter();
    @include row();
    flex-direction: row;
    justify-content: space-between;
    button {
      @include button($blue-1);
      @include truncateText();
    }
  }
}

// Generic Template
.genericTemplatesContainer {
  display: flex;
  flex-direction: column;
  .genericTemplate {
    @include flexStart;
    flex-direction: column;
    width: 250px;
    border: 0.25px solid $light-grey-2;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      height: 56px;
      width: 100%;
      padding: 0 24px;
      img {
        height: 32px;
        width: 32px;
        float: left;
      }
      .texts {
        @include truncateText();
        margin-left: 10px;
        p {
          margin: 0;
        }
        p:nth-of-type(2) {
          color: $light-grey-3;
          font-size: 0.8rem;
        }
      }
    }
    .content {
      img {
        width: 100%;
      }
      .buttons {
        padding: 8px 24px;
        button {
          @include button($blue-1);
        }
      }
    }
  }
}
