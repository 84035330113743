fieldset {
  border-radius: 0.25rem;
}

legend {
  padding: 0 0.5rem;
}

label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

small {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hr-text {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.hr-text > span {
  position: relative;
  display: inline-block;
  color: #929292;
}

.hr-text > span:before,
.hr-text > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #e0e1e3;
}

.hr-text > span:before {
  right: 100%;
  margin-right: 10px;
}

.hr-text > span:after {
  left: 100%;
  margin-left: 10px;
}

.selected-file {
  display: flex;
  justify-content: center;
  flex-direction: row;
  img {
    width: auto;
    height: 50px;
    margin-right: 10px;
  }
  .selected-file-details {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.info_box {
  display: flex;

  .badge {
    margin-left: 10px;
    background-color: #60a2ce;
    color: white;
    margin-right: 15px;
  }
}

.info_btn {
  width: 20px;
  padding: 2px !important;
  font-size: 10px;
  background-color: #0ab9e8;
  color: #fff;
  margin-left: 6px;
  font-weight: 800;
}
