.questionnaireTable {
  margin-bottom: 0;
  tbody tr {
    height: 56px !important;
  }
  .id {
    width: 5%;
  }
  .message {
    width: 30%;
  }
  .possbileAnswers {
    width: 35%;
  }
  .connections {
    text-align: center;
    width: 5%;
  }
  .parentConditions {
    width: 10%;
    text-align: center;
    & > i,
    span {
      cursor: pointer;
    }
  }
  .type {
    width: 5%;
    text-align: center;
    & > i {
      cursor: pointer;
    }
  }
  .checkpoint {
    width: 5%;
    text-align: center;
  }
  .operations {
    width: 10%;
    text-align: right;
  }
}

.answersTable {
  margin-top: 1rem;
  .answer {
    width: 30%;
    cursor: auto !important;
  }
  .intent {
    width: 20%;
  }
  .actionType {
    width: 20%;
  }
  .action {
    width: 40%;
  }
  .delete {
    width: 20%;
    text-align: right;
  }
}

.tagPillContainer {
  display: flex;
  flex-wrap: wrap;
}

.actionTypeButton,
.select {
  width: 100%;
}

.nodeText {
  p {
    font-size: 1rem;
    white-space: pre-wrap;
    margin: 0;
    text-align: center;
  }
}

.valueOfExpression {
  background: lighten(grey, 45);
  white-space: pre-wrap;
  padding: 0.5rem;
}

.checkpointIconContainer {
  button {
    i {
      &.checked {
        color: rgba(0, 0, 0, 1);
      }
      &.unchecked {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.selectlabel {
  span {
    display: none;
  }
}

.removeBtn {
  margin-top: 1.5px;
}
