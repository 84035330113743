.talentPoolTable {
  // table-layout: fixed;
  tbody tr {
    cursor: pointer;
    // height: 56px !important;
  }
  .id,
  .platform {
    width: 5%;
    text-align: center;
  }
  .questionnaireFilledAt {
    width: 12%;
  }
  .labels {
    width: 45;
  }
}

.selectLabel {
  margin-bottom: 5px;
}

.tagPillContainer {
  display: flex;
  flex-wrap: wrap;
}
