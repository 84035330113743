@import '../../scss/vendors/variables';
@import '../../scss/mixins';

.card-header {
  background-color: transparent;
  border: 0;
}
table thead tr th {
  &.logId {
    width: 7%;
    text-align: center;
  }
  &.time {
    width: 20%;
  }
  &.application {
    width: 15%;
  }
  &.user {
    width: 10%;
    text-align: center;
  }
  &.eventType {
    width: 15%;
  }
  &.viewDetails {
    width: 33%;
  }
}
table tbody tr td {
  &.logId,
  &.user {
    text-align: center;
  }
  &.time {
    p {
      margin: 0;
    }
    p:last-child {
      color: darken($secondary, 30);
    }
  }
  &.application {
    @include truncateText;
  }
  &.eventType {
    p {
      margin: 0;
    }
    p:last-child {
      @include truncateText;
      color: darken($secondary, 30);
    }
  }
  &.viewDetails {
    text-align: right;
  }
}
.reveal {
  &:hover {
    i::before {
      content: '\f06e';
    }
  }
}

.select {
  width: 250px;
  max-width: auto;
  display: inline-block;
  margin-left: 2rem;
}
.title-container-row {
  margin-bottom: 1.5rem;
  .title-container-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.datePicker {
  display: inline-block;
}
.exportButton {
  position: absolute;
  right: 15px;
  top: 0;
}

.eventDetailsModal {
  table tbody tr {
    td:first-child {
      width: 25%;
    }
    td:last-child {
      width: 75%;
    }
    .differentTexts {
      display: inline;
      .arrow {
        display: inline;
        margin: 0 0.5rem;
      }
      p {
        width: auto;
        display: inline-block;
        margin: 0;
        padding: 0 5px;
      }
      p:first-child:not(:last-child) {
        color: darken($danger, 10);
        background: lighten($danger, 25);
        text-decoration: line-through;
      }
      a:last-child,
      p:last-child {
        color: darken($success, 15);
        background: lighten($success, 35);
      }
    }
    .sameText {
      display: inline;
    }
  }
}

@media (max-width: 576px) {
  .datePicker {
    width: 200px;
  }
}

@media (max-width: 1237px) {
  .select {
    display: block;
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
}
