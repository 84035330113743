@import '../../scss/variables';

.app-widget {
  display: flex;
  flex-flow: column;
  height: 130px;
  margin: 0 10px 50px 10px;
  background: rgb(253, 253, 253);
  color: rgba(0, 0, 0, 1);
  border-radius: 2px;
  border-top: 5px solid $chatboss-blue;
  box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1), 0 3px 20px 0 rgba(89, 105, 129, 0.05),
    0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.app-widget:hover {
  background: rgb(255, 255, 255);
  box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1), 0 3px 20px 0 rgba(89, 105, 129, 0.2),
    0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.app-widget .header {
  border-width: 100%;
  padding: 10px 20px;
}

.app-widget .header > p {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  border-top: 1px solid rgb(209, 209, 209);
}

.stats .stat-text {
  color: rgb(150, 150, 150);
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats .stat-count {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
