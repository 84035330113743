.cardHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .textContainer {
    width: 70%;
  }
  .buttonContainer {
    display: flex;
  }
}

.addSentenceFormGroup {
  display: flex;
}

.tagPillContainer {
  display: flex;
  flex-wrap: wrap;
}
