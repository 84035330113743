@import '../../scss/variables';

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  .candidatesCount {
    text-align: center;
  }
  .date {
    text-align: center;
  }
}
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem 1.5rem;
  box-shadow: $box-shadow-1;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    color: lighten(#23282c, 50%);
  }
  .cardRowLabel {
    display: none;
    color: grey;
    margin-right: 1rem;
    font-weight: normal;
    float: left;
  }
  .chevron {
    color: rgba(0, 0, 0, 0.55);
  }
  &:hover {
    background-color: #f9f9fa;
    .chevron {
      color: #23282c;
      transition: all 0.2s ease-in-out;
    }
  }
  &:focus {
    outline: none;
  }
  &:nth-child(3) {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  &:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .name {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .candidatesCount {
    text-align: center;
  }
  .date {
    text-align: center;
  }
  .chevron {
    text-align: right;
  }
}
.noTalentPools {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}
.talentPoolTable {
  background-color: #fff;
  tbody tr {
    cursor: pointer;
  }
  tbody tr td i {
    &:hover {
      cursor: pointer;
    }
  }
}
.talentPoolTable thead tr th {
  &.marked {
    text-align: center;
  }
  &.cv {
    text-align: center;
  }
  &.operations {
    text-align: right;
  }
}
.talentPoolTable thead tr td {
  white-space: nowrap;
  vertical-align: center;
  &.marked {
    width: 5%;
    text-align: center;
    & > .markedStar {
      display: inline-block;
      &:focus {
        outline: none;
      }
    }
  }
  &.name {
    width: 15%;
  }
  &.position {
    width: 15%;
  }
  &.email {
    width: 15%;
  }
  &.phone {
    width: 15%;
  }
  &.cv {
    width: 5%;
    text-align: center;
    z-index: 999;
  }
  &.date {
    width: 15%;
  }
  // &.operations {
  //   width: 15%;
  //   text-align: right;
  // }
}
.talentPoolTable tbody tr:last-child {
  border-bottom: 1px solid #c8ced3;
}
.talentPoolTable .unknownData {
  color: rgba(0, 0, 0, 0.35);
}
.star {
  color: #20a8d8;
  &:hover {
    color: #33bef2;
  }
}

// Modal
.basicInformations .title {
  font-size: 1rem;
  font-weight: 500;
}
.basicInformations .informationRow:last-child {
  border-bottom: none;
}
.questionsAnswers .title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
}
.questionsAnswers .informationRow:last-child {
  border-bottom: none;
}
.candidateModal .informationRow {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0.6rem;
  padding: 0.6rem 0;
}
.candidateModal .informationCol {
  padding-left: 0;
}
.candidateModal .modalBody {
  max-height: calc(100vh - 185px);
  overflow-y: auto;
}
.candidateModal .multipleAnswer {
  display: block;
}
