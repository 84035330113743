table thead tr th {
  &.pollSite {
    width: 40%;
  }
  &.date {
    width: 20%;
  }
  &.message {
    width: 40%;
  }
}
