.interviewsTable {
  .name {
    width: 23%;
  }
  .date {
    width: 10%;
  }
  .from {
    width: 8%;
  }
  .to {
    width: 8%;
  }
  .deadline {
    width: 12%;
  }
  .limit {
    width: 4%;
  }
  .positions {
    width: 25%;
    .positionTagPills {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .nofattendees {
    width: 3%;
  }

  .attendees {
    width: 5%;
  }
  .operations {
    width: 5%;
    text-align: right;
  }
}

.timePicker {
  margin: 0;
  padding: 0;
  width: 70px;
  border: 1px solid #dbdbdb;
  border-left: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  input {
    height: 34px;
    padding: 4px;
    font-size: 19px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #484848;
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    -webkit-transition: none;
    transition: none;
    &:focus {
      outline: none;
      border-bottom: 2px solid #008489;
    }
  }
}

.timePickerPopUp {
  background: blue;
}
