.personaSelectorButton {
  width: 36px;
  border-radius: 50% !important;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  margin: 10px 10px;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: contain;
  }
}

.personaDropdownItem {
  padding: 10px;
}

.personaDetails {
  display: flex;
  align-items: center;
  padding: 0;
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  p {
    margin: 0 0 0 10px;
  }
}

//if no user , top up alert box style
.user_box {
  margin: 0 !important;
  width: 197px;
}
