@import 'variables.scss';
@import 'common.scss';

// Text Message
.user {
  @include messageBubble($light-grey, $dark-grey);
}
.bot {
  @include messageBubble($blue-1, $white);
  margin-left: auto;
}

.mapTemplate {
  @include messageBubble($blue-1, $white);
  padding: 0;
  width: 250px;
  a {
    text-decoration: none;
    color: $white;
  }
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
  }
  .addressContainer {
    padding: 0.5rem;
    p {
      margin: 0;
    }
  }
}

// Button Template
.buttonTemplate {
  @include flexCenter;
  button {
    @include button($blue-1, $blue-1);
  }
}

// Quick Reply
.quickReplies {
  @include flexCenter;
  .quickReply {
    @include button($blue-1, $blue-1);
  }
}

// Multi List
.multiListOptions {
  @include flexCenter;
  .mainOption {
    @include button($blue-1, $blue-1);
    font-weight: bold;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
  .subOption {
    @include button($blue-1, $blue-1);
    border-radius: 0;
    margin: 0;
  }
  .lastOption {
    margin-bottom: 0.5rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .disabledOption {
    pointer-events: none;
  }
}

// Generic Template & Generic Template 2
.genericTemplate {
  @include messageBubble($blue-1, $white);
  @include flexCenter;
  .templateImage {
    width: 150px;
  }
  .templateButton {
    @include button($white, $white);
    margin-top: 1rem;
    width: auto;
  }
}
