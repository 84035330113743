body {
  font-family: 'Roboto', sans-serif;
  background: rgb(242, 242, 242);
  padding-right: 0 !important;
}

h6 {
  font-size: 15.5px;
}

// General
.main .container-fluid {
  padding: 0 1rem;
}
.modal-open {
  overflow-y: inherit;
}
.animated {
  animation-duration: 0.8s;
}
.card-header {
  background-color: #fff;
}
.input-error-message {
  display: block;
  font-weight: 500;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}
.form-control.is-invalid,
.form-control.is-valid {
  padding-right: 0;
  background-image: none;
}
textarea.form-control.is-invalid,
textarea.form-control.is-valid {
  padding-right: 12px;
}
.breadcrumb {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.breadcrumb .breadcrumb-item {
  display: inline;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.9rem;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f054';
  font-weight: 900;
  font-size: 0.7rem;
  color: #167495;
  padding-right: 0.9rem;
}
:disabled * {
  pointer-events: none;
}
.badge {
  padding: 0.25rem 0.5rem;
}
// Sidebar
.sidebar-fixed .sidebar {
  width: 250px;
}
.sidebar .nav {
  width: 245px;
}
.sidebar .sidebar-nav {
  width: 245px;
}
.sidebar-footer {
  margin-top: auto;
  background-color: transparent !important;
  color: #73818e;
  text-align: center;
}
.sidebar-minimizer {
  margin-top: 0 !important;
}

.sidebar {
  .nav-link {
    padding: 0.75rem 0.4rem !important;
  }
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
  width: 225px;
}
.sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
  width: 225px;
}

html:not([dir='rtl']) .sidebar {
  margin-left: -245px;
}

@media (min-width: 576px) {
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main {
    margin-left: 245px;
  }

  .container_subscribeList {
    max-width: -webkit-fill-available;
    padding: 0 1rem;
  }
}

// Header NavItem
.app-header .nav-item {
  margin-right: 1rem;
  font-size: 0.95rem;
  &:first-child a {
    margin-right: 1.2rem;
  }
  &:last-child a {
    margin-right: 0;
  }
}

// Disabled Element
.disabled {
  display: unset;
  cursor: not-allowed !important;
  pointer-events: unset !important;
  // display: inline-block;
}

.disabled > button {
  cursor: not-allowed;
  pointer-events: auto;
}

.disabled > button > i {
  cursor: not-allowed;
  pointer-events: none;
}

// Pagination
.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
  border-radius: 0.25rem;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #007aff;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #007aff;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 0;
  color: #fff;
  background-color: #007aff;
  border-color: #007aff;
  cursor: pointer;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

// Platform Logos
.platform-logo {
  font-size: 20px;
  &.messenger i {
    background: -webkit-linear-gradient(#00c6ff, #0078ff);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.viber i {
    background: #665cac;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.hangouts i {
    background: -webkit-linear-gradient(#1ba261, #1f8456);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.livechat {
    height: 20px;
  }
}

// Questionnaire react-digraph Graph Controls
.view-wrapper .graph-controls {
  bottom: 15px;
}

.view-wrapper .graph-controls .slider-button {
  height: auto !important;
}
.react-select-container {
  &.invalid {
    .reactSelect__control {
      border-color: #f86c6b;
    }
  }
}

.name-column {
  width: 23%;
}
.operations-column {
  width: 5%;
  text-align: right;
}

//Required for inline edit items so half buttons / select dropdown not disappears
.table-responsive {
  overflow: visible !important;
}
