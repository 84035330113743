.applicantsTable {
  .appliedAt {
    width: 10%;
  }
  .position {
    width: 20%;
  }
  .platform {
    width: 10%;
    text-align: center;
  }
  .name {
    width: 20%;
  }
  .email {
    width: 20%;
  }
  .phone {
    width: 20%;
  }
}

.selectLabel {
  margin-bottom: 5px;
}
