.tagPillsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: lighten(silver, 21);
  padding: 5px;
  width: 100%;
}

.tagPillContainer {
  display: flex;
}

.tagPill {
  display: flex;
  justify-content: center;
  align-items: center;
  background: lighten(lightblue, 10);
  padding: 5px 7px;
  margin: 8px 0 8px 8px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.span {
  font-size: 10px;
  font-weight: 700;
}

.tagPillOperations {
  flex-direction: row;
  display: flex;
  background: lighten(lightblue, 6);
  margin: 8px 8px 8px 0;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: all 0.5s ease;
  button {
    display: inline-block;
    color: darken(lightblue, 30);
    padding: 7px 9px;
    &:hover {
      color: darken(lightblue, 40);
    }
  }
}

.tagPillInput {
  //height: calc(1em + 0.75rem - 1px);
  padding: 5px 7px;
  //background: lighten(lightblue, 6);
}

.tagCreatePill {
  background: transparent;
  border: 1px dashed darken(lightgreen, 30);
  color: darken(lightgreen, 30);
  font-size: 12px;
  height: 31px;
  padding: 5px 7px;
  margin: 8px 0 8px 8px;
  border-radius: 0.25rem;
  cursor: pointer;
}

.tagPillButton {
  background: transparent;
  border: none;
  color: #fff;
  padding: 5px 10px;
}
