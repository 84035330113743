.feedback-table {
  table-layout: auto;
}
.feedback-table thead tr th {
  padding: 10px;
}
.feedback-table tbody tr td {
  padding: 10px;
}
.feedback-table tbody tr:last-child {
  border-bottom: 1px solid #dee2e5;
}
.feedback-table th {
  &.date {
    width: 20%;
  }
  &.message {
    width: 80%;
  }
}
.chart-container {
  // min-width: 300px;
  height: 400px;
}

ul.bordered-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.bordered-list > li {
  padding: 10px 0;
  border-bottom: 1px solid rgb(212, 212, 212);
}

ul.bordered-list > li.total-feedbacks {
  font-weight: 600;
  border-top: 1px solid rgb(110, 110, 110);
  border-bottom: none;
}
