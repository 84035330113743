.bar {
  height: 3px;
}

.backgroundBar {
  @extend .bar;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.completedBar {
  @extend .bar;
  width: 0;
  transition: width 1s;
}
