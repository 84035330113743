@import 'src/scss/mixins';

.positionsTable {
  thead tr th,
  tbody tr td {
    &.orderNumber {
      width: 5%;
      text-align: center;
    }
    &.name {
      width: 20%;
    }
    &.externalId {
      width: 20%;
    }
    &.startsAt {
      width: 10%;
    }
    &.expiresAt {
      width: 10%;
    }
    &.statusBadge {
      width: 15%;
      text-align: center;
    }
    &.operations {
      width: 20%;
      text-align: right;
    }
  }
}

.prefilterCriteriaTable {
  thead tr th,
  tbody tr td {
    &.shortList {
      width: 7%;
      text-align: center;
    }
    &.category {
      width: 15%;
    }
    &.question {
      width: 28%;
    }
    &.possibleAnswers {
      width: 20%;
      //padding: 0;
    }
    &.valueAndKoInputs {
      width: 18%;
      div > .select,
      label {
        margin-bottom: 10px;
      }
      div > div > label {
        margin-left: 10px;
      }
    }
    &.operations {
      width: 13%;
      text-align: right;
      white-space: nowrap;
      button:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.select {
  flex: 1;
  width: 100%;
}

.shortListTable {
  thead tr th,
  tbody tr td {
    &.category {
      width: 20%;
    }
    &.question {
      width: 40%;
    }
    &.possibleAnswers {
      width: 20%;
    }
    &.answerValue {
      width: 10%;
    }
    &.ko {
      width: 10%;
    }
  }
}

.stepGroupItem {
  display: flex;
  align-items: center;
  position: relative;
  .checkMark {
    position: absolute;
    right: 20px;
  }
}

.possibleAnswersContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .possibleAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px !important;
    font-size: 100%;
    font-weight: normal;
    margin: 0 10px 10px 0;
    white-space: normal!important;
  }
}

.tagPillContainerTop {
  display: block;
}
.tagPillContainer {
  display: flex;
  flex-wrap: wrap;
}

.answerBadgesContainer {
  display: flex;
  flex-direction: column;
  .answerBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    background: lighten(lightblue, 10);
    padding: 8px;
    border-radius: 0.25rem;
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
}

.valueInputsContainer {
  input {
    padding: 8px;
    height: 26px;
    width: 52px;
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
}

.koSwitchContainer {
  display: flex;
  flex-direction: column;
  label {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
}

.cloneSwitches {
  div:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

.positionUrlCard {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  cursor: default;
  .urlContent {
    @include truncateText;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  a,
  span {
    @include truncateText;
    margin-right: 10px;
  }
  img {
    max-width: 25px;
    margin-right: 15px;
  }
}

.positionStatusBadge {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  font-size: 100%;
  font-weight: normal;
}

.possibleAnswerBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin: 0 0.5rem 0.5rem 0;
}

.positionDetailsTable {
  margin-top: 0.8rem;
  .shortList {
    width: 10%;
    text-align: center;
  }
  .category {
    width: 20%;
  }
  .question {
    width: 35%;
  }
  .possibleAnswers {
    width: 35%;
  }
}

.positionNameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  div {
    width: 100%;
  }
  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.positionDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: right;
  }
}

.criteriaCategoriesContainer {
  display: flex;
  flex-wrap: wrap;
}

.shortcutListItem {
  color: #20a8d8;
  &:hover {
    color: #167495;
  }
}

.orderNumberInput {
  width: 50px;
  overflow: hidden;
  padding: 2px 5px;
  border: 3px dashed lighten(grey, 20);
  margin: 0 auto;
  &:hover {
    background: black !important;
  }
}

.filterLabel {
  margin-bottom: 5px;
}
