.container {
  max-width: 200px;
  margin-right: 20px;
  padding: 10px;
  background-color: white;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.image {
  width: 150px;
  height: 100px;
  object-fit: cover;
}
