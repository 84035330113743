table thead tr th {
  &.id {
    width: 7%;
    text-align: center;
  }
  &.name {
    width: 25%;
  }
  &.email {
    width: 25%;
  }
  &.lastLogin {
    width: 25%;
  }
  &.operations {
    text-align: right;
  }
}

table tbody tr td {
  &.id {
    text-align: center;
  }
  &.operations {
    text-align: right;
  }
}

.userDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  p {
    margin: 0 0 0 15px;
    &:nth-of-type(2) {
      color: #8d8e91;
      margin-top: 5px;
    }
  }
}

.lastLoginTime {
  p {
    margin: 0;
  }
}
