@import '../../scss/variables';

.container_subscribeList {
  width: 100% !important;
}

.main .container-fluid {
  padding: 0;
  width: 100%;
  display: contents !important;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.type {
  text-align: center;
}

.subscriberField {
  max-height: 300px;
  overflow: auto;
}

.viberSendFooter {
  label {
    margin-bottom: 2px;
  }
}

.rebuildButton {
  position: absolute;
  top: -6px;
  right: 0;
}

.buildButton {
  margin-top: 16px;
}

.subscriberListLabel {
  max-width: 60%;
  position: relative;
  top: 2px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 500;

  .subscribers {
    text-align: center;
  }

  .date {
    text-align: center;
  }

  .operations {
    text-align: center;
  }

  .chevron {
    max-width: 30px;
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  box-shadow: $box-shadow-1;
  cursor: pointer;

  .cardRowLabel {
    display: none;
    color: grey;
    margin-right: 1rem;
    font-weight: normal;
    float: left;
  }

  .chevron {
    color: rgba(0, 0, 0, 0.55);
    text-align: right;
    max-width: 30px;
  }

  &:hover {
    background-color: #f9f9fa;

    .chevron {
      color: #23282c;
      transition: all 0.2s ease-in-out;
    }
  }

  &:focus {
    outline: none;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .name {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    text-align: center;
    color: #bebebe;

    :hover {
      color: #a7a7a7;
    }
  }

  .subscribers {
    text-align: center;
  }

  .date {
    text-align: center;
  }

  .operations {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: nowrap;

    :not(:first-child) {
      margin-left: 0.5rem;
    }

    .operationButtons {
      button:disabled {
        cursor: not-allowed;
        // pointer-events: unset;
        // visibility: hidden;
      }
    }
  }
}

.disabled {
  background-color: blue;
  display: inline-block;
}

.deleteButton {
  color: #23282c;
}

.noLists {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputMessage {
  color: #f86c6b;
  margin-top: 0.25rem;
  font-size: 0.8rem;
}

// Subscribers Table
table thead tr th {
  &.uuid {
    width: 40%;
  }

  &.platform,
  &.date,
  &.operations {
    width: 20%;
  }

  &.platform {
    text-align: center;
  }

  &.date {
    text-align: left;
  }

  &.operations {
    text-align: right;
  }
}

table tbody tr td {
  &.uuid {
    width: 40%;
  }

  &.platform,
  &.date,
  &.operations {
    width: 25%;
    display: table-cell;
    padding: 0.25rem 0.5rem;
    vertical-align: middle;
  }

  &.platform {
    text-align: center;
  }

  &.date {
    text-align: left;
  }

  &.operations {
    text-align: right;
  }
}

@media (max-width: 991.98px) {
  .cardHeader {
    display: none;
  }

  .card {
    justify-content: start;
    align-items: start;
    flex-direction: column;

    .cardRowLabel {
      display: inline-block;
    }

    .chevron {
      display: none;
    }

    .name,
    .description,
    .subscribers,
    .date,
    .operations {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0.5rem;
      overflow: visible;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .operations {
      border-bottom: 0;

      .operationButtons {
        justify-content: flex-end;
      }
    }
  }
}

.importedCount {
  border-left: 3px solid rgb(0, 127, 0);
}

.unimportedCount {
  border-left: 3px solid rgb(192, 63, 63);
}

.allCount {
  border-left: 3px solid rgb(255, 192, 0);
}


.importedCount,
.unimportedCount,
.allCount {
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;

  p {
    color: gray;
    margin-bottom: 0px;
  }
}