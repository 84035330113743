table {
  .topicName {
    width: 40%;
  }
  .clickCount {
    width: 60%;
  }
  .clickCount {
    text-align: center;
  }
}
