.carousel-snippet-container {
  & button:focus {
    outline: none;
  }
  .carousel-snippet {
    .element {
      .carousel-snippet-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        border-radius: 5px;
        img {
          width: 380px;
          object-fit: cover;
        }
      }
    }
  }
}
