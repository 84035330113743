.subscribed {
  border-left: 3px solid blue;
}

.remaining {
  border-left: 3px solid green;
}

.subscribed,
.remaining {
  p {
    color: gray;
    margin-bottom: 0px;
  }
}
