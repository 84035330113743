@import '../../scss/variables';
.folders-table {
  table-layout: fixed;
}

.folders-table tbody tr {
  cursor: pointer;
}

.folders-table tbody tr:last-child {
  border-bottom: 1px solid #dee2e5;
}

.folders-table thead tr th {
  &.name {
    width: 45%;
  }
  &.date-created {
    width: 15%;
  }
  &.documents {
    width: 25%;
    text-align: center;
  }
  &.gallery {
    width: 10%;
    text-align: center;
  }
  &.operations {
    width: 15%;
    text-align: right;
  }
}

.folders-table tbody tr td {
  &.documents {
    text-align: center;
  }
  &.gallery {
    width: 10%;
    text-align: center;
  }
}

.documents-table {
  box-sizing: border-box;
  table-layout: fixed;
}

.documents-table thead tr th {
  &.name {
    width: 35%;
  }
  &.date-created {
    width: 10%;
  }
  &.attachments {
    width: 10%;
  }
  &.slug {
    width: 30%;
  }
  &.operations {
    width: 15%;
    text-align: right;
  }
}

.documents-table tbody tr td {
  &.name {
    width: 35%;
  }
  &.date-created {
    width: 10%;
  }
  &.attachments {
    width: 10%;
  }
  &.slug {
    width: 30%;
  }
  &.operations {
    width: 15%;
  }
}

.documents-table tbody tr:last-child {
  border-bottom: 1px solid #dee2e5;
}
