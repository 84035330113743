.customListItemsTable {
  .name {
    width: 30%;
  }
  .parent {
    width: 60%;
  }
  .operations {
    width: 10%;
    text-align: right;
  }
}
