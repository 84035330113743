@import '../../../../scss/variables';

.tileContent {
  border-left: 2px solid;
}

.completed {
  border-color: $status-online;
}

.subscribed {
  border-color: $chatboss-blue;
}

.running {
  border-color: $status-waiting;
}

.tileContentValue {
  font-weight: 500;
}

.tileContentLabel {
  opacity: 0.6;
  font-size: 13px;
}
