.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rightContent {
    display: flex;
    align-items: center;
  }
}

.tagPillContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
