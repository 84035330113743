.unrecognized-sentences-table {
  table-layout: auto;
}
.unrecognized-sentences-table tbody tr:last-child {
  border-bottom: 1px solid #dee2e5;
}
.unrecognized-sentences-table th {
  &.date {
    width: 20%;
  }
  &.sentence {
    width: 35%;
  }
  &.entity {
    width: 35%;
  }
  &.user-suggested {
    width: 10%;
  }
  &.conversation-link {
    min-width: 150px;
    text-align: center;
  }
}
.unrecognized-sentences-table td {
  &.user-suggested {
    text-align: center;
    color: #65c965;
  }
  &.conversation-link {
    text-align: right;
  }
}
.entity-badge {
  margin-right: 5px;
  padding: 4px 7px !important;
  font-size: 12px !important;
}
