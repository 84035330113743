.modalButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button:first-of-type {
    margin-right: 0.5rem;
  }
}

.timePicker {
  margin: 0;
  padding: 0;
  width: 70px;
  border: 1px solid #dbdbdb;
  border-left: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  input {
    height: 34px;
    padding: 4px;
    font-size: 19px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #484848;
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    -webkit-transition: none;
    transition: none;
    &:focus {
      outline: none;
      border-bottom: 2px solid #008489;
    }
  }
}

.timePickerPopUp {
  background: blue;
}
