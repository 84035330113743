.tagCategoryCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: lighten(silver, 21);
  height: 50px;
  padding: 20px;
  border-radius: 0.25rem;
  cursor: pointer;
  &:hover {
    background: lighten(silver, 15);
    .operations {
      display: inline-block;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 7px;
  }
  &.activeCard {
    background: lighten(silver, 15);
  }
  .operations {
    display: none;
    button {
      margin: 0 5px;
      border: none;
      background: transparent;
      &:hover {
        color: darken(silver, 40);
      }
    }
  }
  .tagsCount {
    margin-left: 50px;
    font-weight: 700;
  }
}

.tagPillsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: lighten(silver, 21);
  padding: 5px;
}

.tagPillContainer {
  display: flex;
}

.tagPill {
  display: flex;
  justify-content: center;
  align-items: center;
  background: lighten(lightblue, 10);
  padding: 5px 7px;
  margin: 8px 0 8px 8px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.tagPillOperations {
  flex-direction: row;
  display: flex;
  background: lighten(lightblue, 6);
  margin: 8px 8px 8px 0;
  transition: all 0.5s ease;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: all 0.5s ease;
  button {
    display: inline-block;
    color: darken(lightblue, 30);
    padding: 7px 9px;
    &:hover {
      color: darken(lightblue, 40);
    }
  }
}

.tagCreatePill {
  background: transparent;
  border: 1px dashed darken(lightgreen, 30);
  color: darken(lightgreen, 30);
  font-size: 12px;
  height: 31px;
  padding: 5px 7px;
  margin: 8px 0 8px 8px;
  border-radius: 0.25rem;
  cursor: pointer;
}

.tagPillButton {
  background: transparent;
  border: none;
  color: #fff;
  padding: 5px 10px;
}

.employeeTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .employeeTag,
  .employeeTagCategory {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: lighten(lightblue, 10);
    padding: 5px 7px;
    border-radius: 0.25rem;
    margin-right: 5px;
    margin-bottom: 3px;
    &:hover {
      .employeTagRemove {
        display: inline-block;
      }
    }
  }

  .employeeTagCategory {
    background: #c8ced3;
    color: white;
    font-size: 10px;
    line-height: 21px;
    position: relative;
  }

  .employeTagRemove {
    z-index: 100;
    display: none;
    border: none;
    border-radius: 50%;
    padding: 1px;
    margin: 0;
    background-color: #5fb3ce;
    position: absolute;
    top: -12px;
    right: -5px;
    outline-style: none;
    color: #fff;
    width: 21px;
  }
}

.selectGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  .selectGroupBadge {
    background: #ebecf0;
    border-radius: 50%;
    color: #172b4d;
    display: inline-block;
    // font-weight: normal;
    line-height: 8px;
    padding: 7px;
    text-align: center;
  }
}
