@import 'variables.scss';

@mixin messageBubble($bg-color, $font-color, $border-radius) {
  background: $bg-color;
  color: $font-color;
  padding: $message-padding;
  font-size: $message-font-size;
  border-radius: $border-radius;
  max-width: 500px;
  white-space: pre-wrap;
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin button($color, $border-color) {
  height: 1.75rem;
  width: 100%;
  min-width: 100%;
  background: transparent;
  color: $color;
  border: none;
  margin: 0.5rem;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
