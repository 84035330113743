@import '../../../scss/variables';
.title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    .subscribers {
        text-align: center;
    }
    .date {
        text-align: center;
    }
    .operations {
        text-align: center;
    }
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    box-shadow: $box-shadow-1;
    cursor: pointer;
    .cardRowLabel {
        display: none;
        color: grey;
        margin-right: 1rem;
        font-weight: normal;
        float: left;
    }
    .chevron {
        color: rgba(0, 0, 0, 0.55);
        text-align: right;
    }
    &:hover {
        background-color: #f9f9fa;
        .chevron {
            color: #23282c;
            transition: all 0.2s ease-in-out;
        }
    }
    &:focus {
        outline: none;
    }
    &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-bottom: 1.5rem;
    }
    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    .name {
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .description {
        text-align: center;
        color: #bebebe;
         :hover {
            color: #a7a7a7;
        }
    }
    .subscribers {
        text-align: center;
    }
    .date {
        text-align: center;
    }
    .operations {
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
         :not(:first-child) {
            margin-left: 0.5rem;
        }
        .operationButtons {
            button:disabled {
                cursor: not-allowed;
                // pointer-events: unset;
                // visibility: hidden;
            }
        }
    }
}

.disabled {
    background-color: blue;
    display: inline-block;
}

.deleteButton {
    color: #23282c;
}

.noLists {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputMessage {
    color: #f86c6b;
    margin-top: 0.25rem;
    font-size: 0.8rem;
}

// Subscribers Table
table thead tr th {
    &.uuid {
        width: 40%;
    }
    &.inline-edit,
    &.platform,
    &.date,
    &.operations {
        width: 20%;
    }
    &.platform {
        text-align: center;
        color: black !important;
    }
    &.date {
        text-align: left;
    }
    &.operations {
        text-align: right;
    }
    &.long {
        max-width: 30vw;
    }
}

table tbody tr td {
    &.uuid {
        width: 40%;
    }
    &.platform,
    &.date,
    &.operations {
        width: 15%;
        display: table-cell;
        padding: 0.25rem 0.5rem;
        vertical-align: middle;
    }
    &.platform {
        text-align: center;
    }
    &.date {
        text-align: left;
    }
    &.operations {
        text-align: right;
    }
    &.long {
        max-width: 30vw;
    }
}

@media (max-width: 991.98px) {
    .cardHeader {
        display: none;
    }
    .card {
        justify-content: start;
        align-items: start;
        flex-direction: column;
        .cardRowLabel {
            display: inline-block;
        }
        .chevron {
            display: none;
        }
        .name,
        .description,
        .subscribers,
        .date,
        .operations {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0.5rem;
            overflow: visible;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .operations {
            border-bottom: 0;
            .operationButtons {
                justify-content: flex-end;
            }
        }
    }
}

.highlightFirstRow {
    font-weight: bold;
}

@media (max-width: 768px) {
    .table-responsive-mobile {
      overflow-x: auto;
    }
  }