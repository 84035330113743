.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

@media (max-width: 768px) {
  .container {
    max-width: 130%;
  }

  .activeSelect{
    max-width: 80%;
  }
}

.error {
  display: block;
}
