.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tag {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: lighten(lightblue, 10);
    padding: 5px 7px;
    margin: 8px 0 8px 8px;
    border-radius: 0.25rem;

    &:hover {
      .tagDelete {
        display: inline-block;
      }
    }
  }

  .tagDelete {
    display: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: darken(lightblue, 20);
    width: 5px;
    height: 5px;
    position: absolute;
    top: -10px;
    right: 5px;
  }
}
