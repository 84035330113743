table thead tr th {
  &.intent,
  &.count,
  &.ratio {
    width: 33%;
  }
  &.count,
  &.ratio {
    text-align: center;
  }
}

table tbody tr td {
  &.intent,
  &.count,
  &.ratio {
    width: 33%;
  }
  &.count,
  &.ratio {
    text-align: center;
  }
}
