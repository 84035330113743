.pending {
    background-color: rgba(255, 255, 159, 0.7);
}

.closed {
    background-color:  rgba(38, 194, 129,1);
}

@media only screen and (max-width: 768px) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
      font-size: 16px;
    }
  }