.formText {
  color: #f86c6b;
  margin-top: 0.25rem;
  font-size: 0.8rem;
}

.importMessage {
  color: #4dbd74;
  margin-right: 1rem;
  transition: all 10s linear;
  i {
    margin-right: 0.3rem;
    align-self: center;
    // color: lighten(#4dbd74, 25);
  }
}

.spinner {
  margin-right: 0.5rem;
}
