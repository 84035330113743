.badge {
  padding: 0.1rem;
  width: 3rem;
  border-radius: 0.25rem;
  text-align: center;
  display: inline-block;
  &.successBadge {
    background: #daf0da;
    color: darken(#daf0da, 50%);
  }
  &.waitingBadge {
    background: #faedd4;
    color: darken(#faedd4, 50%);
  }
  &.failedBadge {
    background: #f7e6e3;
    color: darken(#f7e6e3, 50%);
  }
}
