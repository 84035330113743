.card-header {
  background-color: #fff;
  padding: 1.25rem;
}
.input-group:not(:first-child) {
  margin-top: 1rem;
}
.btn-save,
.btn-delete {
  margin-left: 1rem !important;
  border-radius: 0.25rem !important;
  width: 4.5rem;
}

.btn-platform-select:hover {
  background-color: #90d4ec;
}
.btn-platform-select:focus {
  box-shadow: none !important;
  outline: none !important;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

@media (max-width: 576px) {
  .btn-platform-select-group {
    width: 100%;
  }
  .btn-create {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }
}
