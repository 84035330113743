$entity-highlight-bg-color: #ffff4f;

// chatbot position
.ConversationHistory_historyContainer__2vLsl {
  overflow: inherit !important;
}

.Messages_messageContainer__39ltn.Messages_botMessage__2wzb- {
  flex-direction: row;
}

.conversationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 96px);
  overflow-y: auto;
  overflow-x: inherit !important;
  padding: 1rem;
  .messageDate {
    text-align: center;
    text-transform: uppercase;
    margin: 0.5rem 0;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
  }
}

.messageContainer {
  display: flex;

  &.userMessage {
    margin-bottom: 0.5rem;
    &:first-of-type {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 0;
    }
    &.noAvatar {
      margin-left: 53px;
    }
    .avatar {
      margin: 0 0.5rem;
    }
  }
  &.botMessage {
    margin-left: auto;
    flex-direction: inherit;
    margin-bottom: 0.5rem;
    &.noAvatar {
      margin-right: 53px;
      margin-bottom: 0.15rem;
    }
    .avatar {
      margin: 0 0.5rem;
      float: right;
    }
  }
  .avatar {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .entityHighlight {
    background: $entity-highlight-bg-color;
  }
  .messageContent {
    .sender {
      font-size: 12px;
      margin-right: 12px;
      color: grey;
    }
  }
}

.intentBadgeContainer {
  border-top: 1px solid #d3d3d4;
  display: block !important;
  margin-top: 5px;
  .intentBadge {
    font-size: 12px !important;
    padding: 3px 5px !important;
    margin-top: 7px;
    font-weight: normal;
  }
}
