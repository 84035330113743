.brand-card-header i {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 1 !important;
}

.brand-card-header{
  height: 5.7rem;
}

.summary {
  padding-left: 1rem;
}

.chart-wrapper {
  height: 150px;
  width: 100%;
  margin: 0.7rem auto;
  &.social-card {
    height: 80px;
  }
}

.total-conversations-widget {
  &:hover {
    background-color: #f8f9f9;
    cursor: pointer;
  }
}

.bg-messenger {
  background-color: #0078ff;
}

.bg-hangouts {
  background-color: #1e8d3e;
}

.bg-livechat {
  background-color: #00adef;
}

.bg-viber {
  background-color: #7360f2;
}

.inactive {
  background-color: #bdc3c7;
}
