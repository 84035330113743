.customFieldsTable {
  margin-bottom: 0;
  .key {
    width: 30%;
  }
  .title {
    width: 30%;
  }
  .namespace {
    width: 30%;
  }
  .operations {
    white-space: nowrap;
    text-align: right;
  }
}
