.application-select {
  margin-left: 50px !important;
}
.application-select-toggle {
  width: 300px;

  background: transparent !important;
  text-align: left !important;
  i {
    margin-top: 4px;
    &:active {
      border: none !important;
    }
    &.fas.fa-chevron-down {
      color: #a6acaf;
    }
  }
  &:hover,
  :active,
  :focus {
    background: transparent !important;
  }
  &:focus,
  :active {
    box-shadow: none !important;
    border: 1px solid #909497;
  }
  &.minimzed {
    display: none;
    width: 70px;
  }
}
.application-dropdown-menu {
  width: 300px;
  height: auto;
  max-height: 400px;
  border: 1px solid #cacecf;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.application-dropdown-item {
  border-bottom: 1px solid #cacecf;
  &:hover {
    background: #f2f3f4;
  }
  &:active {
    color: inherit;
    outline: none;
  }
  &.dropdown-item {
    height: 40px;
  }
}
.dropdown-header {
  background-color: #f3f5f6;
}

.app-header .navbar-toggler {
  margin-left: 15px;
}

.app-header .nav-item:not(:last-child):first-child a {
  margin-right: 0;
}

.users-nav,
.audit-log-nav,
.talent-pools-nav,
.documents-nav {
  margin-left: 10px;
  &.minimized {
    display: none;
  }
}

.toggle-flag {
  width: 22px;
}

.language-dropdown-item {
  padding: 7px 17px;
  img {
    width: 22px;
    margin-right: 10px;
  }
}

@media (max-width: 991.98px) {
  .d-lg-none.navbar-toggler {
    margin: 0 10px;
  }
}

@media (max-width: 767.98px) {
  .application-select {
    margin-left: 0 !important;
  }
  .application-select-toggle {
    display: none !important;
    &.minimzed {
      display: list-item !important;
    }
  }
  .application-dropdown-menu {
    transform: translate3d(0px, 35px, 0px) !important;
  }
  .app-header li.nav-item {
    margin: 0;
  }
  .users-nav,
  .audit-log-nav,
  .talent-pools-nav,
  .documents-nav {
    display: none;
    margin-left: 0;
    &.minimized {
      display: list-item;
    }
  }
}
