@import 'variables.scss';
@import 'common.scss';

// Text Message
.user {
  @include messageBubble($light-grey, $dark-grey);
  &.start {
    border-radius: 21px 21px 21px 4px;
  }
  &.middle {
    border-radius: 4px 21px 21px 4px;
  }
  &.end {
    border-radius: 4px 21px 21px 21px;
  }
}
.bot {
  @include messageBubble($blue-1, $white);
  margin-left: auto;
  &.start {
    border-radius: 21px 21px 4px 21px;
  }
  &.middle {
    border-radius: 21px 4px 4px 21px;
  }
  &.end {
    border-radius: 21px 4px 21px 21px;
  }
  a {
    color: $white;
  }
}

// Image Message
.imageMessage {
  img {
    max-width: 300px;
    border: 1px solid $light-grey-2;
    border-radius: 21px;
  }
}

// Media Message
.mediaMessage {
  img {
    max-width: 200px;
    border: 1px solid $light-grey-2;
    border-radius: 21px;
  }
}

// Sticker Message
.stickerMessage {
  img {
    max-width: 100px;
  }
}

// Button Template
.buttonTemplate {
  @include flexCenter;
  margin-top: 0.25rem;
  button {
    @include button($blue-3, $light-grey-2);
  }
}

// Quick Replies
.quickReplies {
  @include flexCenter;
  flex-direction: row;
  margin-top: 0.25rem;
  max-width: 600px;
  .quickReply {
    @include roundedButton($blue-1, $blue-1);
    &:not(:first-of-type) {
      margin-left: 0.25rem;
    }
  }
}

// Generic Template
.genericTemplatesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .genericTemplate {
    @include flexCenter();
    border: 1px solid $light-grey-2;
    margin-left: 0.5rem;
    width: 150px;
    .header {
      height: 70px;
      padding: 0.25rem;
      .texts {
        p {
          margin: 0;
        }
        p:first-of-type {
          font-weight: bold;
          margin-bottom: 0.25rem;
          font-size: 0.75rem;
        }
        p:last-of-type {
          color: $light-grey-3;
          font-size: 0.65rem;
        }
      }
    }
    .content {
      img {
        width: 100%;
        margin: 0.5rem 0;
      }
      .button {
        @include flexCenter();
        padding: 0.5rem;
        border-top: 1px solid $light-grey-2;
        button {
          background: transparent;
          text-transform: uppercase;
          font-weight: bold;
          color: $blue-1;
          border: none;
        }
      }
    }
  }
}
