$green: #cbe7cb;
$blue: #9ad3ff;
$red: #ffe0dc;

@mixin badge($color) {
  background: $color;
  color: darken($color, 50%);
  padding: 0.15rem 0.3rem;
  min-width: 4rem;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
}

.changelogContainer {
  h1 {
    font-size: 1.1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  h2 {
    font-size: 1rem;
  }
  h3 {
    font-size: 0.9rem;
  }
  .changelog {
    .version {
      padding: 1rem 0;
      margin: 0;
      .latestBadge {
        @include badge(#add6ff);
      }
    }
    .date {
      font-size: 0.8rem;
    }
    &:not(:first-of-type) {
      .version {
        margin-top: 1rem;
      }
    }
  }
}

.changelogContent {
  margin-left: 1.25rem;
}

.categoryContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  .badgeContainer {
    display: inline-block;
    .newBadge {
      @include badge($blue);
    }
    .removedBadge {
      @include badge($red);
    }
    .fixedBadge {
      @include badge($green);
    }
  }
  .contentContainer {
    display: inline-block;
    margin-left: 0.8rem;
    p {
      margin: 0;
    }
    .name {
      padding: 0.2rem 0;
      font-weight: 400;
    }
    .description {
      color: lighten(#23282c, 30%);
    }
  }
}
