.customListCard {
  cursor: pointer;
  .customListCardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .buttonsContainer {
    display: flex;
    flex-wrap: nowrap;
  }
}
