.tabContent {
  margin-bottom: 1.5rem;
}

.employeeTable {
  table tbody tr td {
    word-break: break-word;

    &.operations {
      white-space: nowrap;
      text-align: right;
    }

    &:last-child {
      width: 3%!important;
    }
  }

  table thead tr th {
    &:last-child {
      width: 3%!important;
    }
  }
}
