.DateRangePickerInput {
  border: 2px solid #cdcecf;
  border-radius: 4px;
  transition: all 0.2s linear;
}
.DateRangePickerInput:hover {
  border: 2px solid #a1a2a3;
  transition: all 0.2s linear;
}
.DateInput_input {
  text-align: center;
  font-weight: 400;
  padding: 4px;
}
.DateInput_input__focused {
  border-color: #187b9e;
}
.PresetDateRangePicker_panel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 22px 22px 22px;
}
.PresetDateRangePicker_button {
  display: inline-block;
  flex-grow: 1;
  position: relative;
  height: 100%;
  text-align: center;
  background: none;
  border: 2px solid #187b9e;
  color: #187b9e;
  padding: 5px 15px;
  // margin: 0 5px 0 0;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;
  width: 129px;
  margin-bottom: 5px;
  &:hover {
    border: 2px solid #0c3e4f;
  }
  &:nth-child(even) {
    margin-right: 0;
  }
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #187b9e;
}
.CalendarDay__selected {
  background-color: #187b9e;
  &:hover {
    background-color: #187b9e;
  }
}
.CalendarDay__selected_span {
  background-color: #99d7ed;
  border-color: #85cfe9;
  &:hover {
    background-color: #85cfe9;
    border-color: #85cfe9;
  }
}
.CalendarDay__hovered_span {
  background-color: #c2e7f4;
  border-color: #99d7ed;
  &:hover {
    background-color: #c2e7f4;
    border-color: #99d7ed;
  }
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
.chart-container {
  height: 450px;
}
.chart-type-btn-container {
  margin: 20px 0 0 0;
}
.chart-type-btn {
  z-index: 0 !important;
}
.chart-type-btn:hover {
  background-color: #90d4ec;
}
.chart-type-btn:focus {
  box-shadow: none !important;
  outline: none !important;
}
.export-switch-container:not(:last-child) {
  margin-bottom: 15px;
}
.export-switch {
  display: flex;
  align-items: center;
}
