@import 'variables.scss';
@import 'common.scss';
@import 'src/scss/mixins.scss';

//viber message:
.viberBox {
  padding: 0.5rem;
}

// Text Message
.user {
  @include messageBubble($light-grey, $black, $message-border-radius-user);
}
.bot {
  @include messageBubble($blue-1, $black, $message-border-radius-bot);
  margin-left: auto;
  &.start {
    border-radius: $message-border-radius-bot-start;
  }
  &.middle {
    border-radius: $message-border-radius-bot-middle;
  }
  &.end {
    border-radius: $message-border-radius-bot-end;
  }
}

.galleryTemplate{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    flex-shrink: 0;
    max-width: 150px;
    max-height: 150px;
    min-width: 100%;
    min-height: 100%;
    border-radius: 10%!important;
  }
}

// Generic Template
.genericTemplate {
  @include messageBubble($blue-1, $black, $message-border-radius-generic-template);
  @include flexCenter;
  padding: 0;
  max-width: 215px;

  .templateImage {
    border-radius: 15px 15px 0 0;
    width: 100%;
  }
  .templateTitle {
    @include flexCenter;
    width: 100%;
    padding: 10px;
    p {
      margin: 0;
      font-weight: 500;
    }
  }
  .templateSubTitle {
    width: 100%;
    padding: 10px;
    font-size: 0.8rem;
    background: darken($blue-1, 2);
    p {
      @include truncateText;
      margin: 0;
    }
  }
  .templateButton {
    @include button($black, $black);
  }
  a {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

.imageMessage {
  img {
    max-width: 200px;
  }
}

.viberButtonTemplate {
  @extend .genericTemplate;
  max-width: 500px;
  padding-bottom: 5px;
}

.viberButtonTemplateButtons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 2px;
}

.viberButtonTemplateButton {
  margin: 2px !important;
  background-color: $viber-button;
  min-height: 56px;
  color: white !important;

  &:hover {
    background-color: rgba($viber-button, 0.9);
  }
}

.viberButtonTemplateButtonMarginLeft {
  margin-left: 10px !important;
}

.viberButtonTemplateButtonMarginBottom {
  margin-bottom: 10px !important;
}

.viberButtonTemplateButtonNoMargin {
  margin: 0 !important;
  border-radius: 0;
}

.carouselCard {
  min-width: 250px;
  max-width: 400px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  a {
    cursor: pointer;
  }
}
