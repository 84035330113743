$blue-1: #d8ebff;
$blue-2: #ccd8eb;
$dark-grey: #23282c;
$light-grey: #efefef;
$white: #fff;
$black: #101010;
$viber-button: #074367;

$message-padding: 14px;
$message-font-size: 14px;
$message-border-radius-user: 0 15px 15px 15px;
$message-border-radius-bot: 15px;
$message-border-radius-bot-start: 15px 15px 0 15px;
$message-border-radius-bot-middle: 15px 0 0 15px;
$message-border-radius-bot-end: 15px 0 15px 15px;
$message-border-radius-generic-template: 15px;
$button-border-radius: 15px;
