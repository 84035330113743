@import '../../../scss/style.scss';

.answerContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .answerText i {
    color: darken($status-danger, 50%);
  }
  .answerValue {
    color: darken($chatboss-blue, 8%);
  }
}

.totalPoints {
  text-align: center;
}

.koRow {
  background: $status-danger !important;
}
